import axios from "@axios";

export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        fetchKarmaRequests(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                    .get("/karma-requests", { params: queryParams })
                    .then(response => {
                        resolve(response);
                    })
                    .catch(error => reject(error));
            });
        },
        fetchKarmaRequestStatus() {
            return new Promise((resolve, reject) => {
                axios
                    .get("/list/karma-requests-status")
                    .then(response => {
                        resolve(response);
                    })
                    .catch(error => reject(error));
            });
        },
        fetchKarmaRequest(ctx, id) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`karma-requests/${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error));
            });
        },
        addKarmaRequest(ctx, userData) {
            return new Promise((resolve, reject) => {
                axios
                    .post("karma-requests", userData, {
                        headers: {
                            "Content-Type": "multipart/form-data"
                        }
                    })
                    .then(response => {
                        resolve(response);
                    })
                    .catch(error => {
                        console.log(error);
                        if (error.response.status === 422) {
                            if (
                                typeof error.response.data.errors !==
                                "undefined"
                            ) {
                                const keys = Object.keys(
                                    error.response.data.errors
                                );
                                const values = Object.values(
                                    error.response.data.errors
                                );
                                for (let i = 0; i <= keys.length; i += 1) {
                                    if (
                                        error.response.data.errors.hasOwnProperty.call(
                                            values,
                                            i
                                        )
                                    ) {
                                        reject(new Error(values[0][0]));
                                    }
                                }
                            }
                        } else {
                            reject(error);
                        }
                    });
            });
        },
        updateKarmaRequest(ctx, karma_request) {
            return new Promise((resolve, reject) => {
                axios
                    .patch(`karma-requests/${karma_request.id}`, karma_request.data, {
                        headers: {
                            "Content-Type": "multipart/form-data"
                        }
                    })
                    .then(response => {
                        resolve(response);
                    })
                    .catch(error => {
                        if (error.response.status === 422) {
                            if (
                                typeof error.response.data.errors !==
                                "undefined"
                            ) {
                                const keys = Object.keys(
                                    error.response.data.errors
                                );
                                const values = Object.values(
                                    error.response.data.errors
                                );
                                for (let i = 0; i <= keys.length; i += 1) {
                                    if (
                                        error.response.data.errors.hasOwnProperty.call(
                                            values,
                                            i
                                        )
                                    ) {
                                        reject(new Error(values[0][0]));
                                    }
                                }
                            }
                        } else {
                            reject(error);
                        }
                    });
            });
        },
        removeKarmaRequest(ctx, userId) {
            return new Promise((resolve, reject) => {
                axios
                    .delete(`karma-requests/${userId}`)
                    .then(response => {
                        resolve(response);
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        },
        removeMultipleKarmaRequests(ctx, ids) {
            return new Promise((resolve, reject) => {
                axios
                    .post(`/karma-requests/destroy/`, { ids: ids })
                    .then(response => {
                        resolve(response);
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        },
        getCustomFields(ctx, userId) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/karma-requests/create`, { params: { karma_request_id: userId } })
                    .then(response => {
                        resolve(response);
                    })
                    .catch(error => reject(error));
            });
        },
    }
};
