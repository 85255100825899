<template>
  <div>
    <form-wizard
      :color="isBusy ? '#b0a8ff' : '#38B22D'"
      :title="null"
      :subtitle="null"
      shape="square"
      :finish-button-text="isBusy ? 'Saving...' : 'Save'"
      class="mb-3"
      @on-complete="formSubmitted()"
    >
      <!-- karma-request details tab -->
      <tab-content
        title="Karma Request Details"
        icon="feather icon-file-text"
        :before-change="validationForm"
      >
        <b-overlay :show="isBusy" rounded="sm" :variant="skin">
          <validation-observer ref="accountRules" tag="form">
            <b-form autocomplete="off" id="karma-request-form">
              <b-row>
                <b-col md="6" v-if="employee_option.length">
                  <b-form-group>
                    <label class="required-l" for="employee">Employee</label>
                    <v-select
                      v-model="data_local.requested_for_id"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="employee_option"
                      :reduce="(label) => label.hashid"
                      :clearable="false"
                      label="name"
                      input-id="employee"
                      multiple
                      placeholder="Select Employee.."
                    >
                      <template slot="option" slot-scope="option">
                        {{ option.first_name }} ({{ option.email }})
                      </template>

                      <template slot="selected-option" slot-scope="option">
                        {{ option.first_name }}
                      </template>
                    </v-select>
                  </b-form-group>
                </b-col>

                <b-col md="6">
                  <b-form-group>
                    <label class="w-100" for="karma_program_id"
                      >Karma Program
                      <b-button
                        v-if="$can('karma_program_create')"
                        variant="primary"
                        size="sm"
                        class="float-right"
                        :to="{
                          name: 'add-karma-program',
                        }"
                      >
                        <span class="text-nowrap">Add New</span>
                      </b-button>
                    </label>
                    <v-select
                      v-model="data_local.karma_program_id"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="karma_program_option"
                      :reduce="(val) => val.hashid"
                      @input="(val) => getCategories(val)"
                      label="label"
                      :clearable="false"
                      :searchable="true"
                      input-id="karma_program_id"
                      name="karma_program_id"
                      placeholder="Select Karma Program"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group>
                    <label class="" for="karma_category_id"
                      >Karma Category
                    </label>
                    <v-select
                      v-model="data_local.karma_category_id"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="karma_category_option"
                      :reduce="(val) => val.hashid"
                      @input="(val) => getChildCategories(val)"
                      label="label"
                      :clearable="false"
                      :searchable="true"
                      input-id="karma_category_id"
                      name="karma_category_id"
                      placeholder="Select Karma Category"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group>
                    <label class="" for="sub_karma_category_id"
                      >Sub Karma Category
                    </label>
                    <v-select
                      v-model="data_local.sub_karma_category_id"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="sub_karma_category_option"
                      :reduce="(val) => val.hashid"
                      @input="(val) => getCatalogByCategoryId(val)"
                      label="label"
                      :clearable="false"
                      :searchable="true"
                      input-id="sub_karma_category_id"
                      name="sub_karma_category_id"
                      placeholder="Select Sub Karma Category"
                    />
                  </b-form-group>
                </b-col>

                <b-col md="6">
                  <b-form-group>
                    <label class="w-100" for="karma_catalog_id"
                      >Karma Catalog
                      <b-button
                        v-if="$can('karma_catalog_create')"
                        variant="primary"
                        size="sm"
                        class="float-right"
                        :to="{
                          name: 'add-karma-catalog',
                        }"
                      >
                        <span class="text-nowrap">Add New</span>
                      </b-button>
                    </label>
                    <v-select
                      v-model="data_local.karma_catalog_id"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="karma_catalog_option"
                      :reduce="(val) => val.hashid"
                      @input="(val) => getKarmaPointByCatalogId(val)"
                      label="label"
                      :clearable="false"
                      :searchable="true"
                      input-id="karma_catalog_id"
                      name="karma_catalog_id"
                      placeholder="Select Karma Catalog"
                    />
                  </b-form-group>
                </b-col>

                <b-col md="6" v-if="employee_option.length">
                  <b-form-group label="Karma Point" label-for="karma-point">
                    <validation-provider
                      #default="{ errors }"
                      name="Karma Point"
                      rules="required"
                    >
                      <b-form-input
                        id="karma-point"
                        v-on:keypress="NumbersOnly"
                        v-model="data_local.karma_point"
                        type="number"
                        name="karma_point"
                        :state="errors.length > 0 ? false : null"
                        placeholder="Karma Point"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col md="6">
                  <b-form-group label="Date" label-for="date">
                    <flat-pickr
                      v-model="data_local.date"
                      :config="{
                        dateFormat: 'Y-m-d',
                        altFormat: 'd F Y',
                        altInput: 'true',
                        maxDate: 'today',
                      }"
                      class="form-control"
                      name="date"
                      placeholder="Select Certification Date.."
                    />
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group label="Link" label-for="link">
                    <validation-provider
                      #default="{ errors }"
                      name="Link"
                      rules="url"
                    >
                      <b-form-input
                        id="link"
                        type="url"
                        v-model="data_local.link"
                        name="link"
                        :state="errors.length > 0 ? false : null"
                        placeholder="Link"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <b-row>
                    <b-col md="6">
                      <b-form-group label="Note" label-for="note">
                        <validation-provider
                          #default="{ errors }"
                          name="note"
                          rules=""
                        >
                          <b-form-textarea
                            v-model="data_local.notes"
                            name="notes"
                            placeholder="Note"
                            :state="errors.length > 0 ? false : null"
                          >
                            <small class="text-danger">{{ errors[0] }}</small>
                          </b-form-textarea>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <b-col md="6" class="">
                      <b-form-group label="Attachments" label-for="files">
                        <file-pond
                          ref="pond"
                          name="files[]"
                          max-file-size="5MB"
                          max-files="10"
                          allow-multiple="true"
                          v-bind:files="myFiles"
                          image-preview-height="120px"
                          accepted-file-types="image/*,application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                          file-validate-type-label-expected-types="Expects a PDF, DOC or DOCX file"
                        />
                        <small class="text-warning flex items-center">
                          File size should be less than 5MB and allowed formats
                          are
                        </small>
                        <p>( .doc, .docx, .pdf, image)</p>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-col>
                <b-form-group
                  v-for="(item, index) in customFields"
                  :key="item.id"
                  class="col-md-6"
                >
                  <!-- #default="{ errors }" -->
                  <validation-provider
                    :name="item.name"
                    rules=""
                    #default="validationContext"
                  >
                    <b-form-group
                      v-if="item.type == 'number'"
                      :label="item.label"
                      label-for="input-1"
                    >
                      <b-form-input
                        :id="item.name"
                        :name="`custom_fields_data[${item.name}-${item.id}]`"
                        :value="
                          data_local.get_customfields_data['field_' + item.id]
                        "
                        :placeholder="item.label"
                        type="number"
                        :state="getValidationState(validationContext)"
                      />
                    </b-form-group>

                    <b-form-group
                      v-if="item.type == 'text'"
                      :label="item.label"
                      label-for="input-1"
                    >
                      <b-form-input
                        :id="item.name"
                        :name="`custom_fields_data[${item.name}-${item.id}]`"
                        :value="
                          data_local.get_customfields_data['field_' + item.id]
                        "
                        :placeholder="item.label"
                        type="text"
                        :state="getValidationState(validationContext)"
                      />
                    </b-form-group>

                    <b-form-group
                      v-if="item.type == 'url'"
                      :label="item.label"
                      label-for="input-1"
                    >
                      <b-form-input
                        :id="item.name"
                        :name="`custom_fields_data[${item.name}-${item.id}]`"
                        :value="
                          data_local.get_customfields_data['field_' + item.id]
                        "
                        :placeholder="item.label"
                        type="url"
                        :state="getValidationState(validationContext)"
                      />
                    </b-form-group>

                    <b-form-group
                      v-if="item.type == 'rating'"
                      :label="item.label"
                      label-for="input-1"
                    >
                      <div class="d-flex align-items-center">
                        <star-rating
                          v-model="
                            data_local.get_customfields_data['field_' + item.id]
                          "
                          :show-rating="false"
                          :star-size="30"
                        ></star-rating>
                        <span
                          v-if="
                            data_local.get_customfields_data['field_' + item.id]
                          "
                          :class="`rating-btn btn ${
                            ratingdescription[
                              data_local.get_customfields_data[
                                'field_' + item.id
                              ]
                            ].class
                          }`"
                        >
                          {{
                            ratingdescription[
                              data_local.get_customfields_data[
                                "field_" + item.id
                              ]
                            ].text
                          }}</span
                        >
                        <input
                          type="hidden"
                          v-model="
                            data_local.get_customfields_data['field_' + item.id]
                          "
                          :name="`custom_fields_data[${item.name}-${item.id}]`"
                        />
                      </div>
                    </b-form-group>

                    <b-form-group
                      v-if="item.type == 'password'"
                      :label="item.label"
                      label-for="input-1"
                    >
                      <b-form-input
                        :id="item.name"
                        :name="`custom_fields_data[${item.name}-${item.id}]`"
                        :value="
                          data_local.get_customfields_data['field_' + item.id]
                        "
                        :placeholder="item.label"
                        type="password"
                        :state="getValidationState(validationContext)"
                      />
                    </b-form-group>

                    <b-form-group
                      v-if="item.type == 'textarea'"
                      :label="item.label"
                      label-for="input-1"
                    >
                      <b-form-textarea
                        :name="`custom_fields_data[${item.name}-${item.id}]`"
                        :value="
                          data_local.get_customfields_data['field_' + item.id]
                        "
                        trim
                        :placeholder="item.label"
                      />
                    </b-form-group>
                    <b-form-group
                      v-if="item.type == 'date'"
                      :label="item.label"
                      label-for="input-1"
                    >
                      <flat-pickr
                        :config="{ dateFormat: 'Y-m-d' }"
                        :name="`custom_fields_data[${item.name}-${item.id}]`"
                        :value="
                          data_local.get_customfields_data['field_' + item.id]
                        "
                        class="form-control"
                        placeholder="Select Date.."
                      />
                    </b-form-group>

                    <small class="text-danger">{{
                      validationContext.errors[0]
                    }}</small>
                  </validation-provider>
                </b-form-group>
              </b-row>
            </b-form>
          </validation-observer>
        </b-overlay>
      </tab-content>
    </form-wizard>
  </div>
</template>

<script>
import { FormWizard, TabContent } from "vue-form-wizard";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import vSelect from "vue-select";

import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BForm,
  BFormCheckbox,
  BInputGroup,
  VBTooltip,
  BInputGroupAppend,
} from "bootstrap-vue";
import flatPickr from "vue-flatpickr-component";
import { onUnmounted } from "@vue/composition-api";
import { required, email, numeric } from "@validations";
import store from "@/store";
import karmaRequestStoreModule from "../karmaRequestStoreModule";
import bomb from "@/libs/bomb/bomb";
import StarRating from "vue-star-rating";
import formValidation from "@core/comp-functions/forms/form-validation";
import axios from "@axios";
import Vue from "vue";
import VueCookies from "vue-cookies";
Vue.use(VueCookies);
import moment from "moment";

// file-pond dependencies
import vueFilePond from "vue-filepond";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type/dist/filepond-plugin-file-validate-type.esm";
import FilePondPluginImagePreview from "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.esm";
import FilePondPluginImageValidateSize from "filepond-plugin-image-validate-size";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import FilePondPluginFileEncode from "filepond-plugin-file-encode";

// Create FilePond component
const FilePond = vueFilePond(
  FilePondPluginFileValidateType,
  FilePondPluginImagePreview,
  FilePondPluginImageValidateSize,
  FilePondPluginFileValidateSize,
  FilePondPluginFileEncode
);

export default {
  components: {
    flatPickr,
    ValidationProvider,
    ValidationObserver,
    FormWizard,
    TabContent,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BForm,
    BFormCheckbox,
    BInputGroupAppend,
    BInputGroup,
    vSelect,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    StarRating,
    FilePond,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      skin: store.state.appConfig.layout.skin,
      ratingdescription: [
        {
          text: "Below Average",
          class: "star-poor",
        },
        {
          text: "Below Average",
          class: "star-poor",
        },
        {
          text: "Average",
          class: "star-belowAverage",
        },
        {
          text: "Good",
          class: "star-average",
        },
        {
          text: "Very Good",
          class: "star-good",
        },
        {
          text: "Excellent",
          class: "star-excellent",
        },
      ],
      isBusy: false,
      redirectKarmaRequest: "",
      data_local: {
        requested_for_id: "",
        karma_point: "",
        date: moment().format("YYYY-MM-DD"),
        karma_catalog_id: this.$route.params.id ? this.$route.params.id : "",
        karma_program_id: this.$route.params.karma_program_id
          ? this.$route.params.karma_program_id
          : "",
        karma_category_id: this.$route.params.karma_category_id
          ? this.$route.params.karma_category_id
          : "",
        sub_karma_category_id: this.$route.params.sub_karma_category_id
          ? this.$route.params.sub_karma_category_id
          : "",
        get_customfields_data: [],
      },
      customFields: [],
      employee_option: [],
      karma_catalog_option: [],
      karma_program_option: [],
      karma_category_option: [],
      sub_karma_category_option: [],
      myFiles: [],
      userData: this.$cookies.get("userData"),
    };
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = "app-karma-requests";

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME))
      store.registerModule(USER_APP_STORE_MODULE_NAME, karmaRequestStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME))
        store.unregisterModule(USER_APP_STORE_MODULE_NAME);
    });
    const { refFormObserver, getValidationState, resetForm } = formValidation();

    return {
      refFormObserver,
      getValidationState,
      resetForm,
    };
  },
  created() {
    this.getCustomFields();
    if (this.$route.params.karma_program_id) {
      this.getCategories(this.$route.params.karma_program_id, false);
    }
    if (this.$route.params.karma_category_id) {
      this.getChildCategories(this.$route.params.karma_category_id, false);
    }
    if (this.$route.params.sub_karma_category_id) {
      this.getCatalogByCategoryId(
        this.$route.params.sub_karma_category_id,
        false
      );
    }
  },

  methods: {
    getKarmaPointByCatalogId(hashid) {
      const self = this;
      let getCatalog = self.karma_catalog_option.find(
        (catalog) => catalog.hashid === hashid
      );
      self.data_local.karma_point = getCatalog.karma_point;
    },
    NumbersOnly(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    formSubmitted() {
      const self = this;
      const formData = new FormData(
        document.getElementById("karma-request-form")
      );
      formData.append("requested_for_id", self.data_local.requested_for_id);
      formData.append("karma_catalog_id", self.data_local.karma_catalog_id);
      formData.append("karma_program_id", self.data_local.karma_program_id);
      formData.append("date", self.data_local.date);

      formData.delete("files[]"); // data is the name of the original input file; import to delete
      const files = self.$refs.pond.getFiles();
      const keys = Object.keys(self.$refs.pond.getFiles());

      for (let i = 0; i < keys.length; i += 1) {
        formData.append(`attachments[${i}]`, files[i].file);
      }

      let data = {};
      data.url = "karma-requests/";
      // data.params = self.data_local;
      data.params = formData;
      this.isBusy = true;

      this.$store
        .dispatch("app/store", data)
        .then((res) => {
          // Add this karma-request to NHR
          this.isBusy = false;
          this.$router.push("/karma-requests");
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Karma Request Added",
          //     icon: "BellIcon",
          //     variant: "success",
          //     text: res.data.message,
          //   },
          // });
          this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Karma Request Added',
                icon: 'BellIcon',
                variant: 'success',
                text: res.data.message,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 successful-info",
              hideProgressBar : true,
            });
        })
        .catch((error) => {
          this.isBusy = false;
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Oops! Karma Request Adding Failed",
          //     icon: "BellIcon",
          //     variant: "danger",
          //     text: error,
          //   },
          // });
          this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Oops! Karma Request Adding Failed',
                icon: 'BellIcon',
                variant: 'danger',
                text: error,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
        });
    },
    validationForm() {
      const self = this;

      return new Promise((resolve, reject) => {
        this.$refs.accountRules.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },
    getCustomFields() {
      const self = this;
      self.$store
        .dispatch("app-karma-requests/getCustomFields")
        .then((res) => {
          self.customFields = res.data.data;
          // self.karmaRequestStatusOption = res.data.meta.status;
          self.employee_option = res.data.employee_option;
          self.karma_program_option = res.data.karma_program_option;
          // if (self.karma_program_option.length == 1) {
          //   self.data_local.karma_program_id =
          //     self.karma_program_option[0].hashid;
          //   this.getCategories(self.karma_program_option[0].hashid);
          //   this.getChildCatalogue(self.data_local.karma_program_id);
          // }
        })
        .catch((error) => {
          console.log(error);
          // self.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Oops! Custom Fields Getting Failed",
          //     icon: "BellIcon",
          //     variant: "danger",
          //   },
          // });
          this.$toast({
              component: ToastificationContent,
              props: {
                title: `Oops! Custom Fields Getting Failed`,
                icon:'BellIcon',
                variant : 'danger',
                hideClose: true,
                
              },
                  },
                  {
              timeout : 3000,
              position : 'bottom-center',
              toastClassName:"error-info",
              hideProgressBar : true,
                  })
        });
    },
    getChildCatalogue(catalog_id, reset = true) {
      const self = this;
      let data = {};
      data.url = "karma-programs/get-child-catalogue/" + catalog_id;
      this.isBusy = true;
      this.$store
        .dispatch("app/get", data)
        .then((res) => {
          self.karma_catalog_option = res.data.data;
          this.isBusy = false;
        })
        .catch((error) => {
          console.log(error);
          this.isBusy = false;
          // self.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Oops! Child Catalogue Getting Failed",
          //     icon: "BellIcon",
          //     variant: "danger",
          //   },
          // });
          this.$toast({
              component: ToastificationContent,
              props: {
                title: `Oops! Child Catalogue Getting Failed`,
                icon:'ErrorIcon',
                variant : 'danger',
                hideClose: true,
                
              },
                  },
                  {
              timeout : 3000,
              position : 'bottom-center',
              toastClassName:"error-info",
              hideProgressBar : true,
                  })
        });
    },
    getCategories(karma_program_id, reset = true) {
      const self = this;
      let data = {};
      data.url = "get-category-by-program-id/" + karma_program_id;
      this.isBusy = true;
      this.$store
        .dispatch("app/get", data)
        .then((res) => {
          self.karma_category_option = res.data.data;
          if (reset) {
            self.data_local.karma_category_id = "";
            self.data_local.sub_karma_category_id = "";
            self.data_local.karma_catalog_id = "";
            self.data_local.karma_point = "";
          }
          this.isBusy = false;
        })
        .catch((error) => {
          console.log(error);
          this.isBusy = false;
          // self.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Oops! Category Getting Failed",
          //     icon: "BellIcon",
          //     variant: "danger",
          //   },
          // });
          this.$toast({
              component: ToastificationContent,
              props: {
                title: `Oops! Category Getting Failed`,
                icon:'ErrorIcon',
                variant : 'danger',
                hideClose: true,
                
              },
                  },
                  {
              timeout : 3000,
              position : 'bottom-center',
              toastClassName:"error-info",
              hideProgressBar : true,
                  })
        });
    },
    getChildCategories(category_id, reset = true) {
      const self = this;
      let data = {};
      data.url = "karma-categories/get-child-categories/" + category_id;
      this.isBusy = true;
      this.$store
        .dispatch("app/get", data)
        .then((res) => {
          self.sub_karma_category_option = res.data.data;
          if (reset) {
            self.data_local.karma_catalog_id = "";
            self.data_local.sub_karma_category_id = "";
            self.data_local.karma_point = "";
          }
          this.isBusy = false;
        })
        .catch((error) => {
          console.log(error);
          this.isBusy = false;
          // self.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Oops! Child Categories Getting Failed",
          //     icon: "BellIcon",
          //     variant: "danger",
          //   },
          // });
          this.$toast({
              component: ToastificationContent,
              props: {
                title: `Oops! Child Categories Getting Failed`,
                icon:'ErrorIcon',
                variant : 'danger',
                hideClose: true,
                
              },
                  },
                  {
              timeout : 3000,
              position : 'bottom-center',
              toastClassName:"error-info",
              hideProgressBar : true,
                  })
        });
    },
    getCatalogByCategoryId(category_id, reset = true) {
      const self = this;
      let data = {};
      data.url = "get-catalog-by-category-id/" + category_id;
      data.params = {};
      data.params.karma_program_id = self.data_local.karma_program_id;
      // if (self.data_local.requested_for_id) {
      //   data.params.user_hashid = self.data_local.requested_for_id;
      // } else {
      //   }
      data.params.user_hashid = self.userData.hashid;
      this.isBusy = true;
      this.$store
        .dispatch("app/get", data)
        .then((res) => {
          self.karma_catalog_option = res.data.data;
          if (reset) {
            self.data_local.karma_catalog_id = "";
            self.data_local.karma_point = "";
          }
          this.isBusy = false;
        })
        .catch((error) => {
          console.log(error);
          this.isBusy = false;
          // self.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Oops! Catalog Getting Failed",
          //     icon: "BellIcon",
          //     variant: "danger",
          //   },
          // });
          this.$toast({
              component: ToastificationContent,
              props: {
                title: `Oops! Catalog Getting Failed`,
                icon:'ErrorIcon',
                variant : 'danger',
                hideClose: true,
                
              },
                  },
                  {
              timeout : 3000,
              position : 'bottom-center',
              toastClassName:"error-info",
              hideProgressBar : true,
                  })
        });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
// Import FilePond styles
@import "~filepond/dist/filepond.min.css";
@import "~filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";
@import "@core/scss/vue/pages/page-auth.scss";

.filepond--credits {
  display: none;
}
</style>

<style lang="scss" scoped>
.assign-interview-btn {
  position: absolute;
  right: 180px;
  bottom: 60px;
  border: solid 1px;
}
span.rating-btn {
  width: 130px;
  margin-left: 5px;
  padding: 2px;
  color: #fff;
  border-radius: 2px;
  font-size: 13px;
  text-align: center;
  font-weight: 700;
  -webkit-transition: 0.2s;
  transition: 0.2s;
  line-height: 25px;
}
.star-belowAverage {
  background: #f5c357;
}
.star-poor {
  background: #bababa;
}
.star-average {
  background: #f3d23e;
}
.star-good {
  background: #c1d759;
}
.star-excellent {
  background: #48964d;
}
</style>
